@import 'rsuite/dist/rsuite.css';

a:visited,
a:active,
a:hover,
a:focus {
  text-decoration: none;
}
.apexcharts-canvas .apexcharts-svg {
  overflow: visible;
}

.census-outcome-chart .apexcharts-tooltip.apexcharts-theme-dark {
  color: #000 !important;
}

.prgoress-bar-line {
  height: 100%;
  float: left;
  overflow: hidden;
  display: flex;
  justify-content: center;
  font-weight: 600;
  color: #fff;
  align-items: center;
}

.MuiAutocomplete-popper {
  width: fit-content !important;
}

.avatar-status-container {
  position: relative;
}

.notification-list {
  max-height: 400px;
  overflow: scroll;
}

.risk-text {
  font-size: 14px;
  font-weight: 600;
}

.risk-text.high-risk {
  color: #ff987c;
}

.risk-text.very-high-risk {
  color: #df4839;
}

.risk-text.moderate-risk {
  color: #e88447;
}

.risk-text.unresponsive {
  color: #666666;
}

.risk-text.very-low-risk {
  color: #127463;
}

.risk-text.low-risk {
  color: #329f6b;
}

.card-trophy {
  width: 100%;
  padding: 90px 40px;
  text-align: center;
  background: #414a551c;
  border-radius: 8px;
}

.card-trophy img {
  width: 50px;
}

.census-outcome-chart .apexcharts-tooltip-text-y-label {
  color: #fcfcfc !important;
}

.census-outcome-chart .apexcharts-tooltip .apexcharts-tooltip-text-y-value {
  color: #ffffff !important;
  font-weight: bold !important;
}

.rs-picker-popup {
  background-color: #3f464d !important;
  left: inherit !important;
  right: 95px !important;
}

.rs-picker-popup .rs-picker-daterange-header,
.rs-calendar-table-cell-day,
.rs-calendar-header-title-date {
  color: #e2e4e6;
}

.rs-btn-icon {
  color: #ffffff;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px #51565e !important;
}

.rs-picker-daterange-header {
  border-bottom: none !important;
}

.quick-link-dots {
  width: 100%;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
}

.rs-picker-daterange-menu .rs-calendar:first-child {
  border-right: none !important;
}

.rs-picker-toolbar {
  border-top: 1px solid #51565e !important;
}

.rs-stack-item button {
  color: #e2e4e6;
}

.rs-stack-item button:hover {
  color: #ffffff;
}

@media (max-width: 481.99px) {
  .parentChat .css-1moaqti {
    padding-left: 0px !important;
    padding-right: 0px !important;
    height: 100vh !important;
  }

  .parentChat .MuiGrid-item {
    padding-top: 10px !important;
    padding-bottom: 8px !important;
  }
}
