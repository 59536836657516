.apexcharts-legend-series .apexcharts-legend-marker {
  left: -4px !important;
  right: -4px !important;
}

.apexcharts-legend.apx-legend-position-bottom .apexcharts-legend-series,
.apexcharts-legend.apx-legend-position-top .apexcharts-legend-series {
  gap: 8px;
}
.apexcharts-legend-series {
  display: flex;
  gap: 8px;
}
.apexcharts-tooltip-text-y-value {
  margin-left: 0px !important;
}

.MuiAutocomplete-popper {
  z-index: 9998 !important;
}

.MuiTooltip-popper {
  z-index: 9999 !important;
}

.dashboard-chatHistory {
  overflow-x: hidden;
  height: 100%;
}

::-webkit-scrollbar-corner,
::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #c2c2c2bd; /* Color of the thumb */
  border-radius: 5px; /* Rounded corners for the thumb */
}

::-webkit-scrollbar-track {
  background-color: transparent; /* Color of the track */
}

::-webkit-scrollbar-button {
  display: none; /* Hide scrollbar buttons */
}
